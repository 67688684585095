import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Translation resources
import * as translations from "src/translations";

let locale = process.env.REACT_APP_DEFAULT_LOCALE;

if (URLSearchParams) {
  const urlParams = new URLSearchParams(window.location.search);

  locale = urlParams.get("lang") || locale;
}

i18n.use(initReactI18next).init({
  resources: translations,
  debug: false,
  defaultNS: "common",
  fallbackLng: "fr",
  interpolation: {
    escapeValue: false,
  },
  keySeparator: false,
  lng: locale,
  ns: [
    "availabilities",
    "common",
    "contact",
    "footer",
    "prices",
    "profile",
    "showcase",
    "skills",
    "testimonials",
  ],
});

export default i18n;
