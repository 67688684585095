import React, { FC } from "react";
import { useTranslation } from "react-i18next";

// Components
import Fallback from "components/common/Fallback";

const Scheduler = React.lazy(() => import("components/common/Scheduler"));

const Availabilities: FC = () => {
  const { t } = useTranslation("availabilities");

  return (
    <>
      <section
        id="availabilities"
        className="availabilities-area bg-gray ptb-100"
      >
        <div className="container">
          <div className="section-title">
            <span>{t("title")}</span>
            <h3>{t("subtitle")}</h3>
            <p>{t("introduction")}</p>
          </div>
          <div className="px-3 row justify-content-center">
            <React.Suspense fallback={<Fallback />}>
              <Scheduler />
            </React.Suspense>
          </div>
        </div>
      </section>
    </>
  );
};

export default Availabilities;
