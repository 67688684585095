import React, { FC } from "react";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

const NUMBER_MAX_OF_STARS = 5;

type Props = {
  numberOfStars: number;
};

const Rate: FC<Props> = ({ numberOfStars }) => (
  <>
    {[...Array(NUMBER_MAX_OF_STARS)].map((_, index) => (
      <FontAwesomeIcon
        className={classnames("mr-1 rate", {
          "is-negative": index >= numberOfStars,
        })}
        icon={faStar}
        key={index}
      />
    ))}
  </>
);

export default Rate;
