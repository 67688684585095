/* tslint:disable */
/* eslint-disable */
/* @relayHash 8f914f78e979f55e1255784aad19e7c1 */

import { ConcreteRequest } from "relay-runtime";
export type TestimonialListQueryVariables = {};
export type TestimonialListQueryResponse = {
    readonly testimonials: ReadonlyArray<{
        readonly id: string;
        readonly opinion: string;
        readonly profile: {
            readonly name: string;
            readonly position: string;
            readonly picture: {
                readonly url: string;
            };
        };
    }> | null;
};
export type TestimonialListQuery = {
    readonly response: TestimonialListQueryResponse;
    readonly variables: TestimonialListQueryVariables;
};



/*
query TestimonialListQuery {
  testimonials {
    id
    opinion
    profile {
      name
      position
      picture {
        url
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "testimonials",
    "storageKey": null,
    "args": null,
    "concreteType": "Testimonial",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "opinion",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "profile",
        "storageKey": null,
        "args": null,
        "concreteType": "TestimonialProfile",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "position",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "picture",
            "storageKey": null,
            "args": null,
            "concreteType": "Picture",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "url",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "TestimonialListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "TestimonialListQuery",
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "TestimonialListQuery",
    "id": null,
    "text": "query TestimonialListQuery {\n  testimonials {\n    id\n    opinion\n    profile {\n      name\n      position\n      picture {\n        url\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'c166c9b38d86afa8cb83f8473e08416e';
export default node;
