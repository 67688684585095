/* tslint:disable */
/* eslint-disable */
/* @relayHash e0a170fc060dd78c6adbef5b1cc491e8 */

import { ConcreteRequest } from "relay-runtime";
export type PricingPeriod = "DAY" | "MONTH" | "YEAR" | "%future added value";
export type PriceListQueryVariables = {};
export type PriceListQueryResponse = {
    readonly pricings: ReadonlyArray<{
        readonly currency: string;
        readonly id: string;
        readonly name: string;
        readonly period: PricingPeriod;
        readonly price: number;
    }> | null;
};
export type PriceListQuery = {
    readonly response: PriceListQueryResponse;
    readonly variables: PriceListQueryVariables;
};



/*
query PriceListQuery {
  pricings {
    currency
    id
    name
    period
    price
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "pricings",
    "storageKey": null,
    "args": null,
    "concreteType": "Pricing",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "currency",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "period",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "price",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "PriceListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "PriceListQuery",
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "PriceListQuery",
    "id": null,
    "text": "query PriceListQuery {\n  pricings {\n    currency\n    id\n    name\n    period\n    price\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '379726b1113ad1252201ced98bca4642';
export default node;
