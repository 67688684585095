/* tslint:disable */
/* eslint-disable */
/* @relayHash 8a9167c86f9a024ea6d361d46298c0e2 */

import { ConcreteRequest } from "relay-runtime";
export type SkillListQueryVariables = {};
export type SkillListQueryResponse = {
    readonly skills: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly picture: {
            readonly url: string;
        };
        readonly rate: number;
    }> | null;
};
export type SkillListQuery = {
    readonly response: SkillListQueryResponse;
    readonly variables: SkillListQueryVariables;
};



/*
query SkillListQuery {
  skills {
    id
    name
    picture {
      url
    }
    rate
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "skills",
    "storageKey": null,
    "args": null,
    "concreteType": "Skill",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "picture",
        "storageKey": null,
        "args": null,
        "concreteType": "Picture",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "url",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "rate",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SkillListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "SkillListQuery",
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "SkillListQuery",
    "id": null,
    "text": "query SkillListQuery {\n  skills {\n    id\n    name\n    picture {\n      url\n    }\n    rate\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '367630dd8f173f2eb9ab262d6622d565';
export default node;
