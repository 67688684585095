import React, { FC } from "react";

// Translation
import { useTranslation } from "react-i18next";

// Utils
import Truncate from "react-truncate";

// GraphQL
import { TestimonialListQueryResponse } from "relay-types/TestimonialListQuery.graphql";

type Props = NonNullable<TestimonialListQueryResponse["testimonials"]>[0];

const TestimonialItem: FC<Props> = ({ opinion, profile }) => {
  const { t } = useTranslation("testimonials");
  const [isTruncated, setIsTruncated] = React.useState(false);
  const [isExpanded, setIsExpanded] = React.useState(false);

  const handleTruncate = (isTruncatedNow: boolean) => {
    if (isTruncated !== isTruncatedNow) {
      setIsTruncated(isTruncatedNow);
    }
  };

  const toggleLines = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();

    setIsExpanded((previousIsExpanded) => !previousIsExpanded);
  };

  return (
    <div className="col-lg-4 col-md-6">
      <div className="testimonials-item">
        <div className="client-info">
          <div className="img">
            <img height="85" width="85" src={profile.picture.url} alt={name} />
          </div>
          <div className="client-title">
            <h4>{profile.name}</h4>
            <span>{profile.position}</span>
          </div>
        </div>
        <p>
          <Truncate
            ellipsis={
              <span>
                ... <br />
                <a className="more" href="#" onClick={toggleLines}>
                  {t("read_more")}
                </a>
              </span>
            }
            lines={!isExpanded && 4}
            onTruncate={handleTruncate}
          >
            {opinion}
          </Truncate>
          {!isTruncated && isExpanded && (
            <span>
              <br />
              <a className="less" href="#" onClick={toggleLines}>
                {t("read_less")}
              </a>
            </span>
          )}
        </p>
        <i className="icofont-quote-left" />
      </div>
    </div>
  );
};

export default TestimonialItem;
