import React, { FC } from "react";
import { useTranslation } from "react-i18next";

const Footer: FC = () => {
  const { t } = useTranslation();
  const year = new Date().getFullYear();

  return (
    <footer className="footer-area">
      <div className="copyright-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-7">
              <p>
                Copyright © {year} - {t("footer:all_rights_reserved")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
