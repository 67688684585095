import React, { FC, useEffect, Suspense } from "react";

import {
  Availabilities,
  Contact,
  Fallback,
  Footer,
  NavBar,
  Prices,
  Profile,
  Showcase,
  Skills,
  Testimonials,
} from "components";

const Home: FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <NavBar pageName="home" />
      <Showcase />
      <Profile />
      <Skills />
      <Availabilities />
      <Prices />
      <Testimonials />
      <Contact />
      <Footer />
    </>
  );
};

export default Home;
