import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";
import { LinkContainer } from "react-router-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import { getAssetURL } from "core/utils/assets";

const OFFSET = -87;

type Props = {
  pageName?: string;
};

const NavBar: FC<Props> = (props) => {
  const { t } = useTranslation();

  React.useEffect(() => {
    const element = document.getElementById("navbar");

    document.addEventListener("scroll", () => {
      if (!element) {
        return;
      }

      if (window.scrollY > 170) {
        element.classList.add("is-sticky");
      } else {
        element.classList.remove("is-sticky");
      }
    });
  }, []);

  const closeNavbar = React.useCallback(() => {
    const button = document.getElementById("collapse-btn");

    if (button && window.matchMedia("screen and (max-width: 991px)").matches) {
      button.click();
    }
  }, []);

  return (
    <>
      <Navbar
        sticky="top"
        id="navbar"
        bg="light"
        expand="lg"
        className="navbar navbar-expand-lg navbar-light bg-light"
        collapseOnSelect={true}
      >
        <Container>
          {props.pageName === "home" ? (
            <>
              <Link
                activeClass="active"
                to="home"
                spy={true}
                smooth={true}
                offset={-200}
                duration={800}
                className="navbar-brand"
              >
                <span style={{ fontSize: "1.2rem" }}>thibault boursier</span>
                <img
                  alt="ReactJS"
                  height="25"
                  src={getAssetURL("common/reactjs-big.png")}
                />
              </Link>
            </>
          ) : (
            <LinkContainer exact to="/">
              <Navbar.Brand className="navbar-brand">
                Thibault Boursier
              </Navbar.Brand>
            </LinkContainer>
          )}

          <Navbar.Toggle aria-controls="basic-navbar-nav" id="collapse-btn" />

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Link
                activeClass="active"
                to="profile"
                spy={true}
                smooth={true}
                offset={OFFSET}
                duration={800}
                className="nav-link"
                onClick={closeNavbar}
              >
                {t("profile:title")}
              </Link>

              <Link
                activeClass="active"
                to="skills"
                spy={true}
                smooth={true}
                offset={OFFSET}
                duration={800}
                className="nav-link"
                onClick={closeNavbar}
              >
                {t("skills:title")}
              </Link>

              <Link
                activeClass="active"
                to="availabilities"
                spy={true}
                smooth={true}
                offset={OFFSET}
                duration={800}
                className="nav-link"
                onClick={closeNavbar}
              >
                {t("availabilities:title")}
              </Link>

              <Link
                activeClass="active"
                to="prices"
                spy={true}
                smooth={true}
                offset={OFFSET}
                duration={800}
                className="nav-link"
                onClick={closeNavbar}
              >
                {t("prices:title")}
              </Link>

              <Link
                activeClass="active"
                to="contact"
                spy={true}
                smooth={true}
                offset={OFFSET}
                duration={800}
                className="nav-link"
                onClick={closeNavbar}
              >
                {t("contact:title")}
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default NavBar;
