import React, { FC } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

// Libraries styles
import "bootstrap/dist/css/bootstrap.css";
import "animate.css/animate.min.css";

// Custom styles
import "./assets/scss/style.scss";
import "./assets/scss/responsive.scss";

// Components
import Home from "./pages/Home";
import ScrollUpBtn from "./components/common/ScrollUpBtn";

import { TransitionGroup, CSSTransition } from "react-transition-group";

import { RelayEnvironmentProvider } from "react-relay/hooks";
import RelayEnvironment from "./core/relay/environment";

const App: FC = () => (
  <div className="App">
    <div>
      <Route
        render={({ location }) => (
          <TransitionGroup className="transition-group">
            <CSSTransition
              key={location.key}
              timeout={{ enter: 900, exit: 900 }}
              classNames="fade"
            >
              <section className="route-section">
                <Switch location={location}>
                  <Route path="/home" component={Home} />
                  <Route path="/" exact component={Home} />
                  <Redirect to="/not-found" />
                </Switch>
              </section>
            </CSSTransition>
          </TransitionGroup>
        )}
      />
      <ScrollUpBtn />
    </div>
  </div>
);

const AppRoot: FC = () => (
  <RelayEnvironmentProvider environment={RelayEnvironment}>
    <App />
  </RelayEnvironmentProvider>
);

export default AppRoot;
