import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import Img from "react-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { getAssetURL } from "src/core/utils/assets";

const Profile: FC = () => {
  const { t } = useTranslation("profile");

  return (
    <section id="profile" className="about-area bg-gray ptb-100">
      <div className="container">
        <div className="section-title">
          <span>{t("title")}</span>
          <h3>{t("subtitle")}</h3>
        </div>

        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="about-content">
              <h3>{t("header")}</h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: t("introduction"),
                }}
              />
              <h4>{t("expertise.title")}</h4>
              <ul className="pull-left">
                <li>
                  <FontAwesomeIcon className="mr-2" icon={faCheck} />
                  {t("expertise.items.feature_development")}
                </li>

                <li>
                  <FontAwesomeIcon className="mr-2" icon={faCheck} />
                  {t("expertise.items.proof_of_concept")}
                </li>

                <li>
                  <FontAwesomeIcon className="mr-2" icon={faCheck} />
                  {t("expertise.items.migration_to_reactjs")}
                </li>

                <li>
                  <FontAwesomeIcon className="mr-2" icon={faCheck} />
                  {t("expertise.items.code_review")}
                </li>
              </ul>
              <ul className="clearfix">
                <li>
                  <FontAwesomeIcon className="mr-2" icon={faCheck} />
                  {t("expertise.items.refactoring")}
                </li>

                <li>
                  <FontAwesomeIcon className="mr-2" icon={faCheck} />
                  {t("expertise.items.tests")}
                </li>

                <li>
                  <FontAwesomeIcon className="mr-2" icon={faCheck} />
                  {t("expertise.items.project_start")}
                </li>
              </ul>
              <p>
                <a
                  className="btn btn-primary d-block d-md-inline-block"
                  href="https://www.linkedin.com/in/thibaultboursier/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <FontAwesomeIcon className="mr-2" icon={faExternalLinkAlt} />
                  <span className="align-middle">{t("learn_more")}</span>
                </a>
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 d-none d-lg-block">
            <div className="about-img pl-5">
              <Img
                alt="Thibault Boursier"
                src={getAssetURL("profile/thibault-boursier.jpg")}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Profile;
