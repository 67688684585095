import React, { FC } from "react";

// Utils
import classname from "classnames";
import snakeCase from "lodash/snakeCase";
import { useLazyLoadQuery } from "react-relay/hooks";
import { useTranslation } from "react-i18next";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

// GraphQL
// @ts-ignore
import graphql from "babel-plugin-relay/macro";
import { PriceListQueryResponse } from "relay-types/PriceListQuery.graphql";

const Query = graphql`
  query PriceListQuery {
    pricings {
      currency
      id
      name
      period
      price
    }
  }
`;

const PriceList: FC = () => {
  const { t } = useTranslation("prices");
  const { pricings } = useLazyLoadQuery(
    Query,
    {},
    {
      fetchPolicy: "store-or-network",
    }
  ) as PriceListQueryResponse;

  if (!pricings) {
    return null;
  }

  return (
    <div className="row">
      {pricings.map(({ id, name, period, price }, index) => (
        <div
          key={id}
          className={classname("col-lg-4 col-md-6", {
            "offset-lg-2": index === 0,
          })}
        >
          <div className="pricesTable">
            <h3 className="title">{t(`${name}.name`)}</h3>
            <div className="price-value">
              {price}€<sup style={{ fontSize: "0.8rem" }}>HT</sup>
              <span className="month">/{t(`period.${snakeCase(period)}`)}</span>
            </div>
            <p className="px-5">
              <FontAwesomeIcon className="mr-2" icon={faArrowRight} />
              {t(`${name}.description`)}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PriceList;
