import React, { FC, Suspense } from "react";

// Translation
import { useTranslation } from "react-i18next";
import Fallback from "src/components/common/Fallback";

// Components
import TestimonialList from "./TestimonialList/TestimonialList";

const Testimonials: FC = () => {
  const { t } = useTranslation("testimonials");

  return (
    <>
      <section className="testimonials-area bg-gray ptb-100">
        <div className="container">
          <div className="section-title">
            <span>{t("title")}</span>
            <h3
              dangerouslySetInnerHTML={{
                __html: t("subtitle"),
              }}
            />
          </div>
          <Suspense fallback={<Fallback />}>
            <TestimonialList />
          </Suspense>
          <div className="row">
            <div className="col-md-12 source">
              <span
                dangerouslySetInnerHTML={{
                  __html: t("footnotes.linkedin"),
                }}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonials;
