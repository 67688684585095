import React, { FC, Suspense } from "react";
// Translation
import { useTranslation } from "react-i18next";
// Components
import Fallback from "src/components/common/Fallback";
import PriceList from "./PriceList/PriceList";

const Prices: FC = () => {
  const { t } = useTranslation("prices");

  return (
    <section id="prices" className="prices-area ptb-100">
      <div className="container">
        <div className="section-title">
          <span>{t("title")}</span>
          <h3>{t("subtitle")}</h3>
          <p
            dangerouslySetInnerHTML={{
              __html: t("introduction"),
            }}
          />
        </div>
        <Suspense fallback={<Fallback />}>
          <PriceList />
        </Suspense>
      </div>
    </section>
  );
};

export default Prices;
