import React, { FC } from "react";

// GraphQL
// @ts-ignore
import graphql from "babel-plugin-relay/macro";
import { SkillListQueryResponse } from "relay-types/SkillListQuery.graphql";
import { useLazyLoadQuery } from "react-relay/hooks";

// Components
import Rate from "components/common/Rate";

const Query = graphql`
  query SkillListQuery {
    skills {
      id
      name
      picture {
        url
      }
      rate
    }
  }
`;

const SkillList: FC = () => {
  const { skills } = useLazyLoadQuery(
    Query,
    {},
    {
      fetchPolicy: "store-or-network",
    }
  ) as SkillListQueryResponse;

  if (!skills) {
    return null;
  }

  return (
    <div className="row">
      {skills.map(({ id, name, picture, rate }) => (
        <div key={id} className="col-6 col-lg-3 col-md-6 mb-4">
          <div className="single-skills text-center">
            <img alt={name} height="40" src={picture.url} />
            <h3>{name}</h3>
            <p>
              <Rate numberOfStars={rate} />
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SkillList;
