import React, { FC, Suspense } from "react";

// Translation
import { useTranslation } from "react-i18next";

// Components
import { Link } from "react-scroll";
import Fallback from "src/components/common/Fallback";
import SkillList from "./SkillList/SkillList";

const Skills: FC = () => {
  const { t } = useTranslation("skills");

  return (
    <section id="skills" className="skills-area ptb-100">
      <div className="container">
        <div className="section-title">
          <span>{t("title")}</span>
          <h3>{t("subtitle")}</h3>
          <p>
            {t("introduction")}
            &nbsp;
            <Link to="contact" smooth={true} duration={800}>
              {t("contact_link")}
            </Link>
            .
          </p>
        </div>
        <Suspense fallback={<Fallback />}>
          <SkillList />
        </Suspense>
      </div>
    </section>
  );
};

export default Skills;
