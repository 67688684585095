import { isDev } from "core/utils/environment";
import i18next from "src/i18n";

const URL = isDev
  ? "http://localhost:9000/graphql"
  : "/.netlify/functions/graphql";

async function fetchGraphQL(text: string, variables: Record<string, unknown>) {
  const response = await fetch(URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      locale: i18next.language,
    },
    body: JSON.stringify({
      query: text,
      variables,
    }),
  });

  return await response.json();
}

export default fetchGraphQL;
