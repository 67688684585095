import React, { FC } from "react";

const Fallback: FC = () => (
  <div className="fallback p-5 d-flex align-items-center justify-content-center">
    <div className="spinner-border" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>
);

export default Fallback;
