import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";
import Img from "react-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { getAssetURL } from "src/core/utils/assets";

// Styles
import "react-modal-video/css/modal-video.min.css";

const OFFSET = -87;

const Showcase: FC = () => {
  const { t } = useTranslation("showcase");

  return (
    <div id="home" className="main-banner">
      <div className="d-table">
        <div className="d-table-cell align-bottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-7">
                <div className="main-banner-content">
                  <div className="text-center d-md-none mb-3">
                    <img
                      alt="Thibault Boursier"
                      className="rounded-circle"
                      height="150"
                      src={getAssetURL("showcase/thibault-boursier-small.jpg")}
                      width="150"
                    />
                  </div>
                  <h1>
                    Thibault Boursier
                    <br />
                    <span className="font-weight-light">{t("position")}</span>
                    <img
                      alt="ReactJS"
                      height="45"
                      src={getAssetURL("common/reactjs-big.png")}
                    />
                  </h1>

                  <Link
                    activeClass="active"
                    className="btn btn-primary"
                    href=""
                    duration={800}
                    offset={OFFSET}
                    smooth={true}
                    spy={true}
                    to="profile"
                  >
                    {t("cta.profile")}
                  </Link>

                  <Link
                    activeClass="active"
                    className="btn btn-secondary"
                    href=""
                    duration={800}
                    offset={OFFSET}
                    smooth={true}
                    spy={true}
                    to="availabilities"
                  >
                    <FontAwesomeIcon className="mr-2" icon={faCalendarAlt} />
                    {t("cta.availabilities")}
                  </Link>
                </div>
              </div>
              <div className="col-lg-6 col-md-5 d-none d-md-block">
                <div className="banner-img">
                  <Img
                    alt="Thibault Boursier"
                    src={getAssetURL("showcase/thibault-boursier.jpg")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Showcase;
