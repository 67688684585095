import React, { FC } from "react";
import { useTranslation } from "react-i18next";
// @ts-ignore
import Obfuscate from "react-obfuscate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faMap } from "@fortawesome/free-solid-svg-icons";

const Contact: FC = () => {
  const { t } = useTranslation("contact");

  return (
    <section id="contact" className="contact-area pt-100">
      <div className="container">
        <div className="section-title">
          <span>{t("title")}</span>
          <h3>{t("subtitle")}</h3>
          <p>{t("introduction")}</p>
        </div>

        <div className="row">
          <div className="col-lg-5 col-md-6 col-sm-12 offset-lg-1">
            <div className="contact-box">
              <div className="icon">
                <FontAwesomeIcon icon={faEnvelope} />
              </div>
              <div className="content">
                <h4>{t("email")}</h4>
                <p>
                  <Obfuscate email="contact@thibaultboursier.com" />
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-6 col-sm-12">
            <div className="contact-box">
              <div className="icon">
                <FontAwesomeIcon icon={faMap} />
              </div>
              <div className="content">
                <h4>{t("location")}</h4>
                <p>78 (Yvelines)</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
