import React, { FC } from "react";
// @ts-ignore
import ScrollUpButton from "react-scroll-up-button";

const ScrollUpBtn: FC = () => (
  <ScrollUpButton
    style={{
      backgroundColor: "#131111",
      borderRadius: 50,
      height: 40,
      padding: 8,
      width: 40,
    }}
  />
);

export default ScrollUpBtn;
