import React, { FC } from "react";

// GraphQL
// @ts-ignore
import graphql from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay/hooks";
import { TestimonialListQueryResponse } from "relay-types/TestimonialListQuery.graphql";

// Components
import TestimonialItem from "../TestimonialItem/TestimonialItem";

const Query = graphql`
  query TestimonialListQuery {
    testimonials {
      id
      opinion
      profile {
        name
        position
        picture {
          url
        }
      }
    }
  }
`;

const TestimonialList: FC = () => {
  const { testimonials } = useLazyLoadQuery(
    Query,
    {},
    {
      fetchPolicy: "store-or-network",
    }
  ) as TestimonialListQueryResponse;

  if (!testimonials) {
    return null;
  }

  return (
    <div className="row">
      {testimonials.map((item) => (
        <TestimonialItem key={item.id} {...item} />
      ))}
    </div>
  );
};

export default TestimonialList;
